.secondary {
  background-color: #3a70d4;
}

.secondary:hover {
  background-color: #3a70d4;
}

.success {
  background-color: #19c787;
}

.success:hover {
  background-color: #19c787;
}

.link {
  color: #4074d5;
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
}
.link:hover {
  text-decoration: underline;
}
